import React from "react";
import { Link } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import InviteEmployee from "../InviteEmployee/InviteDialog";
import { FaAddressCard, FaPhoneAlt } from "react-icons/fa";
import { FcManager } from "react-icons/fc";
import { GoMailRead } from "react-icons/go";
import { MdDelete, MdLocationCity } from "react-icons/md";
import MetaInfo from "../../../../shared/getMetaInfo";
import { differenceInDays } from "date-fns";
import { Avatar, Badge, Tooltip, Typography } from "@material-ui/core";
import GenerateID from "../GenerateID";
import InactiveChip from "../../../../shared/lib/Chips/InactiveChip"; 
import ActiveChip from "../../../../shared/lib/Chips/ActiveChip"; 
import RedChip from "../../../../shared/lib/Chips/RedChip"; 
import WarningChip from "../../../../shared/lib/Chips/WarningChip"; 
import FlairTable from "../../../../shared/FlairTable";
import EmployeeHoverCard from '../../../../shared/components/EmployeeHoverCard';
import { ImMoveUp } from "react-icons/im"
import { CalendarFormatter } from "../../../../shared/CalendarFormatter";
import ResetEmail from "../ResetEmail";
import ResetPassword from "../ResetPassword";
import EmployeeNotes from "../EmployeeNotes";

function htmlToText(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const textContent = doc.body.textContent || '';
  return textContent;
}

export default function Presentation(props) {
  const {
    employeelist,
    condition,
    onInviteAgain,
    onDelete,
    branchList,
    isLoading,
    onMovingEmployee,
    actions,
    tableRef,
    onCellClicked,
    onSelectionChange,
  } = props;
  const metaInfo = new MetaInfo();

  const lookupBranchList = Object.assign({}, branchList);
  const [openInviteEmployee, setOpenInviteEmployee] = React.useState(false)

  const getAboutToExpire = (workAuthEndDate, expiredAt) => {
    if (!expiredAt) {
      return '';
    }
    const presentDate = new Date();
    const expiryDate = new Date(workAuthEndDate);
    const daysDiff = differenceInDays(expiryDate, presentDate);
    if (daysDiff < 0) {
      return (
        <WarningChip message={`Expired ${-daysDiff} days ago`} />
      );
    } else if (daysDiff <= 90) {
      return <ActiveChip message={`Remaining ${daysDiff} days`} />
    }
    return ""
  }

  const data = employeelist?.map((employee) => ({
      id: employee.id,
      name: employee.name,
      firstname: employee.firstname,
      lastname: employee.lastname,
      email: employee.email,
      phone: employee.phone,
      branch: branchList.indexOf(employee.branch),
      endClientNames: employee.endClients?.map((client) => metaInfo.clientIdToName(client)).join(", "),
      endClients: employee.endClients,
      dateOfJoining: CalendarFormatter.standardDateFormat(employee.dateOfJoining),
      workAuth: employee.workAuth?.work_type,
      workAuthStartDate: employee.workAuth?.work_issue,
      workAuthEndDate: employee.workAuth?.work_exp,
      workauthExp: (() => {
        const presentDate = new Date();
        const expiryDate = new Date(employee.workAuth?.work_exp);
        const daysDiff = differenceInDays(expiryDate, presentDate);
        // const daysDiffp = differenceInDays(presentDate, expiryDate);
        // const daysDiff = differenceInDays(presentDate, expiryDate);
        if (daysDiff > 90) {
          return '';
        }
        
        return daysDiff;
      })(),
      i9InitiatedOn: employee.i9InitiatedOn,
      I94Exp: "",
      eVerifiedAt: employee.eVerifiedAt,
      reportingManager: metaInfo.emailToName(
        employee.reportingManager
      ),
      totalPlacements: employee.totalPlacements?.length ?? 0,
      activePlacements: employee.activePlacements?.length ?? 0,
      workStatus: employeeStatusList.indexOf(employee.workStatus),
      jobTitle: employee.jobTitle,
      department: departmentList.indexOf(employee.department),
      address: employee.address,
      category: employee.category,
      payrollId: employee.payrollId,
      notes: employee.notes ? 0 : 1,
      employeeNotes: employee.notes,
      status: statusList.indexOf(employee.status),
      exitDate: employee.suspendedData?.latestRelievingDate,
      suspendedReasons: employee.suspendedData?.reasons?.map(
        (i) => `${i?.title} - ${metaInfo.emailToName(i?.updatedBy)}`
      )
      .join(", "),
      suspendedActionReasons: employee.suspendedData?.reasons?.map(
        (i) => `${htmlToText(i?.reason)}`
      )
      .join(", "),
      suspendedData: employee.suspendedData,
    })) ?? [];

  const columnsToDisplay = React.useMemo(() => {
    const columns = [
      {
        title: "",
        field: "bulkActions",
        editable: "never",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        width: 40,
      },
      {
        title: "Name",
        field: "name",
        width: 250,
        render: (rowData) => {
          if (rowData.id)
            return <EmployeeHoverCard employeeID={rowData.id} />;
          else
            return (
              <Typography>
                <table style={{ width: 250 }}>
                  <tr>
                    <td>
                      <Avatar
                        style={{ backgroundColor: "red" }}
                        src={rowData?.imageURL}
                      />
                    </td>
                    <td>&nbsp;</td>
                    <td>
                      {rowData?.name}
                    </td>
                  </tr>
                </table>
              </Typography>
            );
        },
        dataType: "String",
      },
      {
        title: "Email", field: "email", dataType: "String",
        width: 150,
        render: (rowData) => <div style={{ width: 250, paddingLeft: 5 }}>{rowData.email}</div>,
      },
      {
        title: "Phone", field: "phone", dataType: "String",
        width: 150,
        render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}><FaPhoneAlt /> {rowData.phone}</div>,
      },
      {
        title: "Branch",
        field: "branch",
        dataType: "String",
        width: 100,
        lookup: lookupBranchList,
  
      },
      {
        title: "Employee ID",
        field: "id",
        defaultSort: "dsc",
        width: 150,
        render: (rowData) => {
          if (rowData.id)
            return (
              <div style={{ width: 130, paddingLeft: 5 }}>
                <Link
                  style={{ color: "#3f51b5" }}
                  to={"/console/employees/" + rowData.id}
                >
                  <FaAddressCard size={24} /> <b>{rowData.id}</b>{" "}
                </Link>
              </div>
            );
          return (
            <div style={{ width: 130, paddingLeft: 5 }}>
              
            </div>
          );
        },
        dataType: "String",
  
      },
      {
        title: "End Client",
        field: "endClientNames",
        render: (rowData) => {
          return (
            <div>
              {rowData.endClients?.map((client) => (
                <div key={client}>{metaInfo.clientIdToName(client)}</div>
              ))}
            </div>
          );
        },      
        dataType: "String",
        filtering: false,
      },
      {
        title: "Date of Joining",
        field: "dateOfJoining",
        width: 140,
        render: (rowData) => <div style={{ paddingLeft: 5 }}>{rowData.dateOfJoining}</div>,
        dataType: "Date",
  
      },
      { title: "Work Authorization", width: 170, field: "workAuth" },
      { title: "Work authorization start date", field: 'workAuthStartDate', width: 240 },
      { title: "Work authorization end date", field: 'workAuthEndDate', width: 240 },
      {
        title: "Work Authorization Expiry",
        field: "workauthExp",
        width: 200,
        dataType: "String",
        render: (rowData) => {
          const remainingDays = rowData.workauthExp;
          
          if (remainingDays > 0)
            return (<ActiveChip message={`Remaining ${remainingDays} days`}/>);
          else if (remainingDays < 0)
            return (<WarningChip message={`${-remainingDays} days ago`}/>);
          else
            return (<span></span>);
        },
        
      },
      {
        title: "I94 Expiry",
        field: "I94Exp",
        width: 150,
        dataType: "String",        
      },  
      { title: "I9", width: 100, field: "i9InitiatedOn" },
      { title: "E-Verify", width: 100, field: "eVerifiedAt" },
      {
        title: "Reporting manager",
        field: "reportingManager",
        width: 200,
        render: (rowData) => {
          if (rowData.reportingManager)
            return (<div style={{ paddingLeft: 5 }}>
              <table>
                <tr>
                  <td><FcManager size={24} /></td>
                  <td>&nbsp;</td>
                  <td><b>{rowData.reportingManager}</b></td>
                </tr>
              </table>
            </div>);
         return (
          <div style={{ paddingLeft: 5 }}>
            
          </div>
        );
        }
        ,
        dataType: "String",
      },
      {
        title: "Total Placements",
        field: "totalPlacements",
        dataType: "String",
        width: 130,
        render: (rowData) => {
          return (
            <div align="center">
              <Badge
                color="primary"
                showZero={false}
                badgeContent={rowData.totalPlacements}
              />
            </div>
          );
        },
  
      },
      {
        title: "Active Placements",
        field: "activePlacements",
        dataType: "String",
        width: 130,
        // hidden: activePlacementsState,
        render: (rowData) => {
          return (
            <div align="center">
              <Badge
                color="primary"
                showZero={false}
                badgeContent={rowData.activePlacements}
              />
            </div>
          );
        },
      },
      {
        title: "Employee status",
        field: "workStatus",
        dataType: "String",
        width: 150,
        lookup: {
          0: "Bench",
          1: "Working",
          2: "Training",
          3: "Prospect"
        },
  
      },
      {
        title: "Job title", field: "jobTitle",
        width: 150,
        render: (rowData) => <div style={{ paddingLeft: 5 }}>{rowData.jobTitle}</div>,
        dataType: "String",
      },
      {
        title: "Department",
        field: "department",
        dataType: "String",
        width: 150,
        lookup: {
          0: "Java",
          1: "DevOps/Cloud",
          2: "Networking/Security",
          3: "Python",
          4: "QA",
          5: ".Net",
          6: "Data Science",
          7: "Big Data",
          8: "CRM",
          9: "Legal",
          10: "HR",
          11: "Accounts",
          12: "Bench sales",
        },
  
      },
      {
        title: "Employee Home Address",
        field: "address",
        dataType: "String",
        width: 200,
        // hidden: true,
        // hiddenByColumnsButton: true,
        render: (rowData) => {
          if (rowData.address) {
            return (
              <div style={{ width: 400, paddingLeft: 5 }}>
                <table>
                  <tr>
                    <td><MdLocationCity size={24} /></td>
                    <td>&nbsp;</td>
                    <td>{rowData.address}</td>
                  </tr>
                </table>
              </div>
            );
          } 
          return (
            <div align="center" style={{ width: 130, paddingLeft: 5 }}>
              --
            </div>
          );
        },      
      },
      {
        title: "Category",
        field: "category",
        width: 100,
        dataType: "String",
        align: "center",
      },
      {
        title: "Payroll ID",
        field: "payrollId",
        width: 100,
        dataType: "String",
        render: (rowData) => <div style={{ width: 150, paddingLeft: 5 }}>{rowData.payrollId}</div>,
      },
      {
        title: "Notes",
        field: "notes",
        dataType: "String",
        width: 80,
        align: "center",
        lookup: {
          0: "Notes Available",
          1: "Notes Not Available",
        },
        render: (rowData) => {
          return (
            <EmployeeNotes
              key={rowData?.name}
              rowData={rowData}
              fetchActiveEmployees={() => {}}
            />
          );
        },
  
      },
      {
        title: "Exit Date",
        field: "exitDate",
        dataType: "String",
        width: 100,
        // render: (row) => {
        // 	const { suspendedData } = row;
        // 	return (
        // 		<table className="table table-bordered table-hover table-sm">
        // 			<tbody>
        // 				{suspendedData?.reasons?.map((item) => {
        // 					return (
        // 						<tr className="p-0" key={item?.id}>
        // 							<td className="border-0">
        // 								{CalendarFormatter.standardDateFormat(item?.updatedAt)}
        // 							</td>
        // 						</tr>
        // 					);
        // 				})}
        // 			</tbody>
        // 		</table>
        // 	);
        // },
      },
      {
        title: "Action Type - Action By",
        field: "suspendedReasons",
        dataType: "String",
        hide:true,
        render: (row) => {
          const { suspendedData } = row;
          return (
            <table className="table table-bordered table-hover table-sm">
              <tbody>
                {suspendedData?.reasons?.map((item) => {
                  return (
                    <tr className="p-0" key={item?.id}>
                      <td className="border-0">
                        {item?.title}
                      </td>
                      <td className="border-0">{metaInfo.emailToName(item?.updatedBy)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        },
      },
      {
        title: "Action Reasons",
        field: "suspendedActionReasons",
        dataType: "String",
        hide:true,
        render: (row) => {
          const { suspendedData } = row;
          return (
            <table className="table table-bordered table-hover table-sm">
              <tbody>
                {suspendedData?.reasons?.map((item) => {
                  return (
                    <tr className="p-0" key={item?.id}>
                      <td className="border-0">{htmlToText(item?.reason)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        },
      },
      {
        title: "Status",
        field: "status",
        dataType: "String",
        width: 100,
        lookup: {
          0: "Inactive",
          1: "Active",
          2: "Suspended",
          3: "OnHold",
        },
        render: (rowData) => {
          if (rowData.status === 1)
            return (
              <ActiveChip message="Active" style={{ marginTop: "10px" }} />
            );
          else if (rowData.status === 2)
            return (
              <RedChip message="Exit" style={{ marginTop: "5px" }} />
            );
          else if (rowData.status === 3)
            return (
              <Chip
                style={{
                  width: "100%",
                  backgroundColor: "#fcb532",
                  color: "white",
                }}
                label="OnHold"
                size="small"
              />
            );
          return (
            <InactiveChip message="Inactive" style={{ marginTop: "10px" }} />
          );
        },
        customFilterAndSearch: (value, rowData) => {
          if (value.length === 0) return true;
          return value.includes(rowData.status.toString());
        },
  
      },
      {
        title: "Actions",
        field: "",
        dataType: "",
        width: 220,
        render: (rowData) => {
          return (
            <span className="d-flex">
              {rowData.status === 0 && (
                <span key={rowData.email} className="d-flex">
                  {/* email send */}
                  <Tooltip title="send Invite">
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() => onInviteAgain([rowData])}
                    >
                      <GoMailRead size={20} />
                    </IconButton>
                  </Tooltip>
  
                  {/* delete icon */}
                  <Tooltip title="Delete Employee">
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() => {
                        if (
                          window.confirm(`Are you sure you want to delete ${rowData.email}?`)
                        )
                          onDelete(rowData.id ? rowData.id : rowData.email);
                      }}
                    >
                      <MdDelete size={20} />
                    </IconButton>
                  </Tooltip>
  
                  {/* If Id generated for inactive employee means EmployeeID will be passed or else emailid  */}
                  <GenerateID email={rowData.email} employeeid={rowData.id} />
                </span>
              )}
              {rowData.status === 1 && (
                <span className="d-flex">
                  <ResetPassword email={rowData.email} />
                  <Tooltip title="Move to Staff">
                    <IconButton
                      style={{ color: "red" }}
                      onClick={() =>
                        onMovingEmployee(rowData.id, { type: "staff" })
                      }
                    >
                      <ImMoveUp size={20} />
                    </IconButton>
                  </Tooltip>
                  <ResetEmail oldEmail={rowData.email} />
                </span>
              )}
            </span>
          );
        },
  
      },
    ];

    if (condition === 2) {
      return columns.filter((e) => e.title !== "Actions");
    }
    if (!onSelectionChange) {
      return columns.filter((e) => e.field !== "bulkActions");
    }
    return columns;
  }, [condition, onSelectionChange, onInviteAgain, onDelete, onMovingEmployee, lookupBranchList, metaInfo]);

  const tableActions = {
    icon: () => <button className="btn btn-success px-6 btn-md text-high ">Invite Employee</button>,
    tooltip: "Invite employee",
    isFreeAction: true,
    position: "toolbar",
    onClick: () => setOpenInviteEmployee(true)
  }

  const getRowHeight = React.useCallback((params) => {
		const { data } = params;
		let maxHeight = 43;
		const { suspendedData, endClients } = data;
		const suspendedReasonsDataLength = suspendedData?.reasons?.length ?? 0;

		if (suspendedReasonsDataLength > 1) {
			const height = 43 * suspendedReasonsDataLength;
			maxHeight = height > maxHeight ? height : maxHeight;
		} else if (endClients?.length > 1) {
      const height = 43 * endClients?.length;
      maxHeight = height > maxHeight ? height : maxHeight;
    }

		return maxHeight;
	}, []);

  return (
    <div>
      <FlairTable
        tableRef={tableRef}
        title="Employees Report"
        columns={columnsToDisplay}
        data={data}
        isLoading={isLoading}
        actions={actions ?? tableActions}
        getRowHeight={getRowHeight}
        onCellClicked={onCellClicked}
        onSelectionChange={onSelectionChange}
      />

      <InviteEmployee
        template={false}
        heading="Invite new employee"
        text="Enter the email address of the new employee"
        hideBtn={true}
        open={openInviteEmployee}
        hideInviteEmployeeDialog={() => setOpenInviteEmployee(false)}
      />
    </div>
  );
}


export const departmentList = [
  "Java",
  "DevOps/Cloud",
  "Networking/Security",
  "Python",
  "QA",
  ".Net",
  "Data Science",
  "Big Data",
  "CRM",
  "Legal",
  "HR",
  "Accounts",
  "Bench sales",
];

export const employeeStatusList = ["Bench", "Working", "Training", "Prospect"];

export const statusList = ["inactive", "active", "suspended", "onHold"];