import React ,{useCallback,useState}from 'react'
import { Link } from "react-router-dom"
import { connect } from "react-redux";
import MetaInfo from "../../../../shared/getMetaInfo"
import FlairTable from "../../../../shared/FlairTable"
import { useInvoiceListContext } from "./context";
import EditInvoice from "./Actions/EditInvoice"
import InvoicePreviewAction from "./Actions/InvoicePreviewAction"
import MailToClient from "./Actions/MailToClient"
import VoidInvoice from "./Actions/VoidInvoice"
import Payment from "./Actions/Payment"
import PaymentsHistory from "../../../Payments/components/List/Actions/PaymentHistory"
import EditVoidInvoice from './Actions/EditVoidInvoice';
import EditFollowup from './Actions/EditFollowup';
import { differenceInDays } from 'date-fns'
import EmployeeHoverCard from '../../../../shared/components/EmployeeHoverCard';
import Button from "react-bootstrap/Button"
import ActiveChip from "../../../../shared/lib/Chips/ActiveChip"; 
import InactiveChip from "../../../../shared/lib/Chips/InactiveChip"; 
import InfoChip from "../../../../shared/lib/Chips/InfoChip"; 
import WarningChip from "../../../../shared/lib/Chips/WarningChip"; 
import RedChip from "../../../../shared/lib/Chips/RedChip"; 
import Accounts from '../../../../utils/Accounts';
import { useMutation, useQuery } from '@tanstack/react-query';
import { exportInvoices, getInvoiceIds, getInvoices } from '../../../../api/services/default/invoices';
import SimpleBackdrop from '../../../../shared/backDrop';
import { FormControlLabel, FormGroup, Switch, withStyles } from '@material-ui/core';
import { getCompanyDetails, updateCompanyDetails } from '../../../../api/services/default/company-details';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function dataMapper(invoices, employeeID, services, branchList,clientID,isArView) {
  return invoices?.map((invoice) => {
    if (isArView && clientID && (invoice.isPaymentDone || invoice.isVoid)) return null;

    if (employeeID && invoice.employeeID !== employeeID) {
      return null;
    }
    const branch = new MetaInfo().getEmployeeKey(invoice.employeeID, "branch");
    const timesheets = Object.values(invoice?.selectedTimesheets ?? {});
    const timesheetsActivity = Object.keys(invoice?.selectedTimesheets ?? {});
    const list = timesheetsActivity.sort(
      (a, b) => Date.parse(new Date(a)) - Date.parse(new Date(b))
    );
    const openBalanceFormatted =
      invoice.grandTotal -
      (invoice?.receivedAmount || 0) -
      (invoice?.paymentDiscountAmount || 0);
    const invoiceName = invoice?.invoiceName;
    const submittedExpenses = invoice.selectedExpenses;
    const firstExpense = submittedExpenses.length > 0 ? submittedExpenses[0] : {};
    const expenseType = firstExpense?.expenseType;
    let activity;
  
    if (list.length > 0) {
      activity = list[0] + "-" + list[list.length - 1];
    } else if (invoiceName) {
      activity = invoiceName;
    } else if (expenseType) {
      activity = expenseType;
    } else {
      activity = "--";
    }
  
    return {
      id: invoice.id,
      createdAt: invoice.createdAt,
      createdBy: invoice.createdBy,
      invoiceDate: invoice.invoiceDate,
      invoiceDueDate: invoice.dueDate,
      invoiceDueDateTimestamp: invoice.dueDate,
      duedays: differenceInDays(new Date(), new Date(invoice.dueDate)),
      clientID: invoice.clientID ? invoice.clientID : "",
      clientName: invoice.clientID
        ? new MetaInfo().clientIdToName(invoice.clientID)
        : "--",
      activity: activity,
      companyDetails: invoice.companyDetails,
      invoiceBy: invoice.invoiceBy,
      placementID: invoice.placementID || "--",
      employeeID: invoice.employeeID || "--",
      invoiceStatus: invoice.type,
      employeeName: invoice.employeeID
        ? new MetaInfo().emailToName(invoice.employeeID)
        : "--",
      isMailedToClient: invoice.isMailedToClient,
      isVoid: invoice.isVoid,
      isPaymentDone: invoice.isPaymentDone,
      selectedTimesheets: invoice?.selectedTimesheets || {},
      selectedExpenses: invoice.selectedExpenses || [],
      discount: invoice.discount,
      netTerms: invoice.netTerms,
      billingrate: (
        services.getBillingRatesInvolvedInTheTimesheets(timesheets) ?? []
      ).join(", "),
      otBillingrate:(
        services.getOTBillingRatesInvolvedInTheTimesheets(timesheets) ?? []
      ).join(", "),
      hrs: services.getTotalTime(timesheets.map((sh) => sh.standardTime)),
      otHrs:services.getTotalTime(timesheets.map((sh) => sh.OTtime)),
      grandTotal: invoice?.grandTotal || 0,
      invoicedAmount: invoice?.grandTotal || 0,
      receivedAmount: invoice?.receivedAmount || 0,
      latestPaymentDate: invoice?.latestPaymentDate || "",
      // moreInformation: invoice.moreInformation,
      isClientApproved: invoice.isClientApproved,
      isClientRejected: invoice.isClientRejected,
      // clientRejectedDetails: invoice.isClientRejected
      //   ? invoice.clientRejectedDetails
      //   : "",
      openBalance: Accounts.roundOffAmount(openBalanceFormatted),
      notifiers: invoice.notifiers,
      branch: branch === undefined ? -1 : branchList.indexOf(branch),
      paymentDiscountAmount: invoice?.paymentDiscountAmount || 0,
      voidReason: new DOMParser()
        .parseFromString(invoice?.voidReason, "text/html")
        .body.textContent.trim(),
      followupComment: invoice?.followupComment || "",
      tag: invoice?.tag || "",
      voidComment: invoice?.voidReason || "",
      // checked: invoice.checked,
    };
  }).filter(Boolean) ?? [];
}

const conditionMap = {
  "1": "due",
  "2": "generated",
  "3": "sent",
  "4": "void",
  "5": "paid",
  "6": "all"
} 

function InvoiceListTable(props) {
  const { loggedInEmployee, branchList, employeeID, clientID, tabValue ,isArView} = props
  const { services } = useInvoiceListContext()
  const lookupBranchList = Object.assign({}, branchList);
  const type = conditionMap[tabValue]
  const isManager = !clientID && !employeeID;
  const metaInfo = new MetaInfo();
  const [checkedAutoGenerate, setCheckedAutoGenerate] = useState(false);

  const { data: invoicesList, isLoading } = useQuery({
    queryKey: ["getInvoices", type, { clientID, employeeID }],
    queryFn: () => getInvoices(type, isManager, clientID, employeeID),
    enabled: type && type !== 'all' && type !== 'paid' 
  })

  const { data: companyConfig, isLoading : isLoadingCompanyDetails } = useQuery({
    queryKey: ["getCompanyDetails"],
    queryFn: () => getCompanyDetails(),
    onSuccess(data) {
			setCheckedAutoGenerate(data?.invoiceDetails?.invoiceAutoGenerate);
		},
  })

  const useUpdateCompanyDetails = useMutation({
    mutationFn: ({ data }) => updateCompanyDetails(data),
  });

  const handleAutoGenerate = async () => {
    setCheckedAutoGenerate(!checkedAutoGenerate);
    await useUpdateCompanyDetails.mutateAsync({
      data: {
        ...companyConfig,
        invoiceDetails: {
          ...companyConfig.invoiceDetails,
          invoiceAutoGenerate: !checkedAutoGenerate,
        },
      },
    });
  } 

  const { mutateAsync: exportInvoicesAsync, isLoading: isExporting } = useMutation({
    mutationFn: () => exportInvoices(type, clientID, employeeID),
    onSuccess(response) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${Date.now()}_Invoices-${type}.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  })

  const onGridReady = useCallback(async (params) => {
		const datasource = {
			getRows: async (params) => {
				try {
					const { startRow, endRow } = params.request;
					const filterModel = params.request.filterModel;
          console.log('filterModel', filterModel)
					const employeeIds = [
						...new Set([
							...(filterModel?.employeeID?.values || []),
							...(filterModel?.employeeName?.values || []),
						]),
					];
          const clientIds = [
            ...new Set([
              ...(filterModel?.clientID?.values || []),
              ...(filterModel?.clientName?.values || []),
            ]),
          ];
          const invoiceIds = [
            ...new Set([
              ...(filterModel?.id?.values || []),
            ]),
          ];
          const skip = startRow;
          const take = endRow - startRow;
					const data = await getInvoices(
						type,
            isManager,
            isManager ? clientIds : clientID,
            isManager ? employeeIds : employeeID,
            invoiceIds,
            skip,
            take
					);
					const invoices = dataMapper(
						data.invoices,
						employeeID,
						services,
            branchList
					);
					params.successCallback(invoices, data.total);
				} catch (error) {
					console.error(`[getRows] ${type}`, error);
					params.failCallback();
				}
			},
		};
		params.api.setServerSideDatasource(datasource);
	}, [type, employeeID]); 

  const defaultColumns = [
    {
      title: "Invoice no. ",
      field: "id",
      width: 180,
      dataType: "String",
      render: (row) => {
        if (row.isVoid) return <span className='d-flex' >
          {/* <EditVoidInvoice invoiceID={row.id} voidReason={row.voidReason} employeeID={employeeID} /> */}
          &nbsp;
          <span className="custom-chip chip-danger"><span style={{ fontSize: '12px' }} >{`${row.id}`}</span></span>
        </span>
        else if (row.isPaymentDone)
          return <ActiveChip message={row.id} />
        else if (row.isMailedToClient)
          return <span className='d-flex' >
            {row.followupComment && <EditFollowup invoiceID={row.id} followupComment={row.followupComment} tagType={row.tag} />}
            <InactiveChip message={row.id} />
          </span>
        return <InfoChip message={row.id} />
      },
    },
    {
      title: "Tag Type",
      field: "tag",
      align: "center",
      type: "string"
    },
    {
      title: "PC",
      field: "placementID",
      width: 100,
      dataType: "String",
      render: (rowData) => {
        if (rowData.placementID !== "--")
          return (
            <Link to={"/console/placements/" + rowData.employeeID + "/" + rowData.placementID}>
              {rowData.placementID}
            </Link>
          )
        else
          return "--"
      },
    },
    {
      title: "Billable Client",
      field: "clientName",
      width: 150,
      dataType: "String",
      render: (rowData) => {
        if (rowData.clientID !== "--")
          return <Link to={"/console/clientslist/" + rowData.clientID}>{rowData.clientName}</Link>
        else
          return "--"
      },
    },
    {
      title: "Employee Id",
      field: "employeeID",
      dataType: "String",
      width: 150,
      render: (rowData) => {
        if (rowData.employeeID !== "--")
          return <Link to={"/console/employees/" + rowData.employeeID}>{rowData.employeeID}</Link>
        else
          return "--"
      }
    },    
    {
      title: "Employee",
      field: "employeeName",
      width: 300,
      dataType: "String",
      render: (row) => {
        if(row.employeeID !== '--')
          return <EmployeeHoverCard employeeID={row.employeeID} />
        else
          return "--"
      }
    },
    // {
    //   title: "Client's Interest",
    //   field: "latestPaymentDate",
    //   dataType: "",
    //   render: ({ isClientApproved, isClientRejected, clientRejectedDetails = {} }) => {
    //     if (isClientApproved) return <span className="custom-chip chip-success">Approved</span>
    //     else if (isClientRejected)
    //       return (
    //         <span className="custom-chip chip-danger">
    //           Rejected&nbsp;
    //           <div>
    //             <Tooltip placement="top" title={clientRejectedDetails.reason}>
    //               <InfoIcon />
    //             </Tooltip>
    //           </div>
    //         </span>
    //       )
    //     return ""
    //   },
    // },
    {
      title: "Branch",
      field: "branch",
      width: 100,
      dataType: "",
      lookup: lookupBranchList,
    },
    {
      title: "Activity",
      field: "activity",
      width: 180,
    },
    {
      title: "Standard Hrs",
      field: "hrs",
      align: "center",
      width: 100,
      type: "string"
    },
    {
      title: "Billing rate",
      field: "billingrate",
      align: "center",
      width: 120,
    },
    {
      title: "OT Hrs",
      field: "otHrs",
      align: "center",
      width: 100,
      type: "string"
    },
    {
      title: "OT Billing rate",
      field: "otBillingrate",
      align: "center",
      width: 120,
    },
    {
      title: "Net terms",
      field: "netTerms",
      align: "center",
      width: 100,
      type: "string"
    },
    {
      title: "Invoiced Date",
      field: "invoiceDate",
      width: 150,
      dataType: "Date",
    },
    {
      title: "Due Date",
      field: "invoiceDueDate",
      width: 150,
      dataType: "Date",
    },
    {
      title: "Due Days",
      field: "duedays",
      align: "center",
      width: 150,
      render: (row) => {
        if (!row.isPaymentDone && !row.isVoid)
          if(row.duedays <= 0)
            return <ActiveChip message="CURRENT" />
          else
            return <WarningChip message={row.duedays + " days"} />
        else
          return ""
      },
      type: "string"
    },
    {
      title: "Invoiced Amount",
      field: "invoicedAmount",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Received Amount",
      field: "receivedAmount",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Discounted Amount",
      field: "paymentDiscountAmount",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Latest payment Date",
      field: "latestPaymentDate",
      width: 150,
      dataType: "Date",
    },
    {
      title: "Open Balance",
      field: "openBalance",
      width: 150,
      dataType: "Numeric",
      type: "currency",
    },
    {
      title: "Actions",
      field: "",
      dataType: "",
      width: 250,
      render: (row) => (
        <div className="d-flex">
          <EditInvoice invoiceID={row.id} />
          <InvoicePreviewAction
            followupComment={row.followupComment}
            invoiceID={row.id}
            isVoid={row.isVoid}
            isPaymentDone={row.isPaymentDone}
            tagType={row.tag}
            employeeID={employeeID}
          />
          {/* <InvoiceTimeline invoiceID={row.id} grandTotal={row.grandTotal} /> */}
          <PaymentsHistory id={row.id} totalAmount={row.grandTotal} />
          <MailToClient
            invoiceID={row.id}
            notifiers={row.notifiers}
            isMailedToClient={row.isMailedToClient}
            isVoid={row.isVoid}
            isPaymentDone={row.isPaymentDone}
            clientName={row.clientName}
            companyDetails={row.companyDetails}
            invoiceDueDate={row.invoiceDueDate}
            openBalance={row.openBalance}
            selectedTimesheets={row.selectedTimesheets}
            selectedExpenses={row.selectedExpenses}
          />
          <VoidInvoice 
            invoiceID={row.id} 
            isVoid={row.isVoid} 
            isPaymentDone={row.isPaymentDone} 
            isPreviewVoid={false} 
            employeeID={employeeID} 
          />
          {row.isMailedToClient && !row.isVoid && loggedInEmployee != row.employeeID ? (
            <Payment
              invoicedAmount={row.invoicedAmount}
              openBalance={row.openBalance}
              paymentDiscountAmount={row.paymentDiscountAmount}
              invoiceID={row.id}
              isPaymentDone={row.isPaymentDone}
              receivedAmountTillNow={row.receivedAmount}
              discountReceivedTillNow={row.paymentDiscountAmount}
              employeeID={employeeID}
            />
          ) : null}
        </div>
      ),
    },
  ]


  if(type === "all") {
    defaultColumns.splice(2, 0, { title: "Invoice Status", field: "invoiceStatus", cellStyle: { width: 150, minWidth: 150 }, dataType: "String" })
  }
  else if(type === "void") {
    defaultColumns.splice(2, 0, {
      title:"Void Comment",
      field:"voidReason",
      cellStyle: {
        width: 150,
        minWidth: 150
        },
      dataType: "String",
      render: (row) => {
        if (row.isVoid) return <span className='d-flex' >
          <EditVoidInvoice invoiceID={row.id} voidReason={row.voidComment} employeeID={employeeID} />
        </span>
      },
    })
  }

  const sendActions = {
    icon: () => (
      <div style={{display:"flex"}}>
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch checked={checkedAutoGenerate} onChange={handleAutoGenerate} name="disableAutoGenerate"  />}
            label="Auto Generate"
            disabled = {isLoadingCompanyDetails}
            />
        </FormGroup>
        <Link to='/console/invoiceslist/newinvoice'>
          <Button className="btn btn-success px-4 btn-md text-high">Add New Invoice</Button>
        </Link>
      </div>
    ),
    tooltip: 'Add New Invoice',
    isFreeAction: true,
    position: 'toolbar',
  }

  const serverSidePagination = type === "all" || type === "paid"

  const getContextMenuItems = useCallback((params) => {
    return [
      'copy',
      'copyWithHeaders',
      'copyWithGroupHeaders',
      'separator',
      {
        name: 'Export',
        subMenu: [
          {
            name: 'CSV Export',
            action: () => params.api.exportDataAsCsv(),
            icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
          },
          {
            name: 'Excel Export',
            action: () => params.api.exportDataAsExcel(),
            icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
          },
          {
            name: 'Excel Export (All Data)',
            action: exportInvoicesAsync,
            icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
          }
        ],
        icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
      },
    ]
  }, [exportInvoicesAsync])

  const columns = serverSidePagination
		? defaultColumns.map((column) => {
        if (column.field === 'employeeID') {
          return {
            ...column,
            filterParams: {
              values: (params) => params.success(Object.keys(metaInfo.names)),
              suppressSelectAll: true,
              defaultToNothingSelected: true,
            },
            sortable: false,
          }
        }      
        if (column.field === 'employeeName') {
          return {
            ...column,
            filterParams: {
              values: (params) => params.success(Object.keys(metaInfo.names)),
              valueFormatter: (params) => metaInfo.emailToName(params.value),
              suppressSelectAll: true,
              defaultToNothingSelected: true,
            },
            sortable: false,
          }
        }
        if (column.field === 'clientName') {
          return {
            ...column,
            filterParams: {
              values: (params) => params.success(Object.keys(metaInfo.clients)),
              valueFormatter: (params) => metaInfo.clientIdToName(params.value),
              suppressSelectAll: true,
              defaultToNothingSelected: true,
            },
            sortable: false,
          }
        }
        if (column.field === 'id') {
          return {
            ...column,
            filterParams: {
              values: async (params) => {
                const response = await getInvoiceIds(type, isManager, clientID, employeeID);
                return params.success(response);
              },
              suppressSelectAll: true,
              defaultToNothingSelected: true,
            },
            sortable: false,
          }
        }
				return {
					...column,
					sortable: false,
					menuTabs: ["generalMenuTab", "columnsMenuTab"],
				};
		  })
		: defaultColumns;
  if (serverSidePagination) {
    return (
      <>
        <SimpleBackdrop openBackdrop={isExporting} />
        <FlairTable 
          title="Invoices Report"
          columns={columns}
          actions={sendActions}
          onCustomGridReady={onGridReady}
          serverSidePagination
          hideSearch
          getContextMenuItems={getContextMenuItems}
        />
      </>
    )
  }
  
  const rows = dataMapper(invoicesList?.invoices ?? [], employeeID, services, branchList,clientID,isArView); 
  return (
    <div>
        <FlairTable
          title="Invoices Report"
          data={rows}
          columns={columns}
          isLoading={isLoading}
          actions={sendActions}
        />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    loggedInEmployee: state.firebase.auth.uid,
    branchList: state.employee?.dynamicProfileRender?.profileTemplate?.data?.sections?.personal?.fields?.filter((field) => field.name === "branch")?.[0]?.values || []
  };
};

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListTable)

