import React, { useEffect, useRef, useState } from 'react'
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle as MuiDialogTitle,
	Grid,
	IconButton,
	Typography,
	withStyles,
} from '@material-ui/core'
import styles from '../../../styles/dialogStyles'
import { useInvoiceListContext } from '../context'
import Validations from '../../../../../shared/validation'
import InvoiceListTable from '../InvoiceListTable'
import CloseIcon from '@material-ui/icons/Close'
import { Constants } from '../../../../../shared/constants'

export default function RenderAmounts({
	openBalance,
	invoicedAmount,
	clientId,
	range,
}) {
	const { services, state } = useInvoiceListContext()
	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		if (range < 5) {
			setOpen(true)
			services.getInvoicesByDueDays(clientId, range)
		}
	}

	const handleClose = () => {
		setOpen(false)
	}

	const descriptionElementRef = useRef(null)
	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				descriptionElement.focus()
			}
		}
	}, [open])

	const getTitleByRange = (value) => {
		if (value in Constants.AR_AGING_RANGES) {
			return Constants.AR_AGING_RANGES[value]
		}

		return ''
	}

	const DialogTitle = withStyles(styles)((props) => {
		const { children, classes, onClose, ...other } = props
		return (
			<MuiDialogTitle disableTypography className={classes.root} {...other}>
				<Typography variant='h6'>{children}</Typography>
				{onClose ? (
					<IconButton
						aria-label='close'
						className={classes.closeButton}
						onClick={onClose}
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		)
	})

	return (
		<React.Fragment>
			<div className='c-pointer' onClick={handleClickOpen}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography>
							Open Balance: {Validations.currencyFormatterUs(openBalance)}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							Invoiced Amount: {Validations.currencyFormatterUs(invoicedAmount)}
						</Typography>
					</Grid>
				</Grid>
			</div>
			<Dialog
				scroll='paper'
				maxWidth='lg'
				fullWidth
				open={open}
				onClose={handleClose}
			>
				<DialogTitle id='scroll-dialog-title' onClose={handleClose}>
					{getTitleByRange(range)}
				</DialogTitle>
				<DialogContent dividers={true}>
					<DialogContentText
						style={{ color: 'black' }}
						ref={descriptionElementRef}
						tabIndex={-1}
					>
						<InvoiceListTable clientID={clientId} isClientInvoices = {true} isArView={true} />
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	)
}
